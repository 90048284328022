export function calc (qlist, answerList) {
  const goodSurviveGuy = []
  const badSurviveGuy = []
  const checkAnswer = []
  const coinResult = {
    coin: 0,
    copywriting: 0
  }
  let adam = true
  let girl = true
  let monalisa = true
  answerList.forEach((answer, index) => {
    if (answer) {
      if (qlist[index].a) {
        goodSurviveGuy.push(answer)
      } else {
        badSurviveGuy.push(qlist[index].type)
      }
    }
    if (qlist[index].a === answer) {
      checkAnswer.push(true)
    } else {
      checkAnswer.push(false)
    }
  })
  coinResult.coin = badSurviveGuy.length
  badSurviveGuy.forEach((qType) => {
    if (qType === 'monalisa') {
      monalisa = false
    } else if (qType === 'adam') {
      adam = false
    } else if (qType === 'girl') {
      girl = false
    }
  })
  if (goodSurviveGuy.length + badSurviveGuy.length >= 5) {
    if (badSurviveGuy.length > 0) {
      coinResult.copywriting = 3
    } else {
      coinResult.copywriting = 1
    }
  } else {
    if (badSurviveGuy.length > 0) {
      coinResult.copywriting = 4
    } else {
      coinResult.copywriting = 2
    }
  }

  return {
    checkAnswer: checkAnswer,
    coinResult: coinResult,
    adam: adam,
    girl: girl,
    monalisa: monalisa
  }
}
