<template>
    <div class="bg container">
        
        <div class="social-login">
            <img class="logo_l" src="@/assets/img/logo_l.png" alt="">
            <div class="login">
                <p>歡迎來到數位公民群島！<br><br>為記錄您的體驗進度，請選擇以下管道登入。請注意使用社群帳號登入後我們將收到您的信箱等相關資訊；若選擇訪客登入則不會收到您的任何資訊，但會無法記錄您的體驗進度，部分功能無法體驗。未滿13 歲小朋友可以請爸爸媽媽開 Google家庭帳號進入唷!<br><br>詳細請見<span style="font-weight:700;cursor:pointer;" @click="goPrivacy()">服務與隱私權政策條款</span></p>
                <h3>－ 登入後開始體驗 －</h3>
                <!-- <div class="social-btn pointer">
                    <GoogleLogin :callback="callback" prompt auto-login/>
                </div>
                <div class="social-btn pointer" v-for="(item, index) in btnList" :key="index" @click="login(index)">
                    <img :src="item" alt="">
                </div> -->
                <ul>
                    <li class="pointer" v-for="(item, index) in btnList" :key="index" @click="login(index)">
                        <img :src="item" alt="">
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import googleBtn from '@/assets/img/google_login_btn.png'
import lineBtn from '@/assets/img/line_login_btn.png'
import guestBtn from '@/assets/img/guest_login_btn.png'
import { ref, readonly, getCurrentInstance, inject, toRefs } from 'vue'
import { decodeCredential } from "vue3-google-login"
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import axios from "axios";
export default {
    setup(props, target){
        const currentInstance = getCurrentInstance();
        const btnList = ref([googleBtn, lineBtn, guestBtn]);
        const store = useStore();
        const router = useRouter();
        function login(type){
            switch (type) {
                case 0:
                    window.location.href = 'https://digitalworld-iwin.tw/auth/google';
                    break;
                case 1:
                    window.location.href = 'https://digitalworld-iwin.tw/auth/line';
                    break;
                case 2:
                    store.dispatch('SetAccountType','guest')
                    target.emit('closeLogin');
                    break;
                default:
                    break;
            }
        }
        function goPrivacy(){
            router.push({name:'privacy'});
        }
        const callback = (response) => {
            const userData = decodeCredential(response.credential)
        }
        return { btnList: readonly(btnList), login, callback, goPrivacy }
    }
}
</script>
<style lang="scss" scoped>
.bg{
    position: absolute;
    left:0%;
    top:0%;
}
.social-login{
    width:282px;
    position: absolute;
    top:48%;
    left:50%;
    transform: translate(-50%, -50%);
    .social-btn{
        border-radius: 5px;
        margin-bottom: 15px;
        // &:nth-of-type(1){
        //     box-shadow: 0px 4px 4px 0px rgba(0,0,0,.25);
        // }
    }
    .logo_l{
        width: 105%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }
    .login{
        padding: 30px 22px;
        border-radius: 8px;
        box-shadow: 0px 4px 4px 0px rgba(0,0,0,.25);
        background-color:#fff;
        p{
            font-size:12px;
            letter-spacing: 0.1em;
            line-height: 1.5;
            margin-bottom: 5%;
        }
        ul{
            display: flex;
            padding: 0 20%;
            justify-content: space-around;
            li{
                width:18%;
            }
        }
    }
    h3{
        font-size: 16px;
        color:#055078;
        margin-bottom: 20px;
    }
}
</style>