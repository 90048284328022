import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vue3GoogleLogin from 'vue3-google-login'

let gauthClientId = "848189704529-j4c8kt2mintcf5h1bcfm6mig8j363ti2.apps.googleusercontent.com";
const gAuthOptions = { clientId: gauthClientId, scope: 'email', prompt: 'consent', fetch_basic_profile: false }

const app = createApp(App)

app.use(vue3GoogleLogin, {
    clientId: gauthClientId
})
app.use(store).use(router).mount('#app');