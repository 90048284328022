<template>
    <nav role="navigation">
        <div id="menuToggle">
        <input type="checkbox" v-model="menuToggle" />
        <span></span>
        <span></span>
        <span></span>
        </div>
    </nav>
    <ul id="menu" :class="{ menuOpen: menuToggle }" class="nSerif">
        <!-- <div class="menuToggle">
            <input type="checkbox" v-model="menuToggle" />
            <span></span>
            <span></span>
            <span></span>
        </div> -->
        <a href="#" @click="menuClick('aboutIsland')"><li>關於數位公民群島</li></a>
        <a href="#" @click="toIWin"><li>關於 iWIN網路內容防護機構</li></a>
        <a href="#" @click="menuClick('share')"><li>分享本站</li></a>
        <a href="#"  @click="menuClick('contact')"><li>聯繫我們</li></a>
        <li>主辦單位｜</li>
        <li>支持單位｜<br><p>LINE TW (台灣連線股份有限公司)｜Meta｜台北市電腦商業同業公會</p>
</li>
    </ul>
</template>
<script>
import { ref } from "vue";
export default {
    emits:['menuClick'],
    setup(props, {emit}){
        let menuToggle = ref(false);
        function menuClick(to){
            emit('menuClick', to);
        }
        function toIWin(){
            gtag('event', '「iWIN超連結」', {
                '事件描述': '「iWIN超連結」點擊次數',
            });
            window.open('https://i.win.org.tw/');
        }
        return { menuToggle, menuClick, toIWin }
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_menu.scss";
nav {
    width: 100%;
    position: absolute;
    z-index: 99;
    // width: 65px;
    position: absolute;
    right: 20px;
    width:18px;
    top:20px;
}
#menu{
    >li{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        padding-bottom: 0;
        line-height: 2;

        p {
            font-weight: 700;
            font-size: 16px;
            color:#055078;
        }

        &:nth-of-type(1){
            &:after{
                content:"";
                display: inline-block;
                width:65px;
                padding-bottom: 15%;
                background-size: 100%;
                background-image: url(~@/assets/img/iWIN.png);
                background-repeat: no-repeat;
            }
        }
        // &:nth-of-type(2){
        //     //padding-left: 15%;
        //     padding-top: 5%;
        //     display: flex;
        //     align-items: center;
        //     span{
        //         display: inline-block;
        //         background-size: 100%;
        //         background-repeat: no-repeat;
        //         &:nth-of-type(1){
        //             width: 39px;
        //             padding-bottom: 6%;
        //             background-position-y: center;
        //             background-image: url(~@/assets/img/Line.png) ;
        //         }
        //         &:nth-of-type(2){
        //             width: 96px;
        //             padding-bottom: 13%;
        //             background-image: url(~@/assets/img/meta.png) ;
        //         }
        //         &:nth-of-type(3){
        //             width: 69px;
        //             padding-bottom: 12%;
        //             background-image: url(~@/assets/img/TCA.png);
        //         }
        //     }
        //     // &:after{
        //     //     content:"";
        //     //     display: inline-block;
        //     //     width:104px;
        //     //     padding-bottom:19%;
        //     //     background-size: 100%;
        //     //     background: url(~@/assets/img/meta.png) left center , url(~@/assets/img/TCA.png) right center;
        //     //     background-repeat: no-repeat;
        //     // }
        // }
    }
}
</style>
