<template>
    <div class="passportAlert">
        <div class="wrap">
            <img class="cross" src="@/assets/img/maker/cross.png" alt="" @click="closeAlert">
            <div class="passport" :style="{backgroundImage:`url(${cover})`}">
                <img :src="grade" alt="">
            </div>
            <button class="submit pointer" @click="share">分享護照</button>
            <button class="submit pointer" @click="goPassport">查看內容</button>
        </div>
    </div>
</template>
<script>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
export default {
    setup(props, target) {
        const router = useRouter();
        const store = useStore();
        const cover = computed(()=>{
            return `/img/cover/cover${store.getters.passportData.cover - 1}.png`
        })
        const grade = computed(()=>{
            return `/img/grade/grade${store.getters.passportData.grade}.png`
        })
        const shareUrl = computed(()=>{
            return store.state.passportInfo.share_url;
        })
        const accountType = computed(()=>{
            return store.state.accountType;
        })
        function goPassport(){
            router.push({name:'passport'});
        }
        function closeAlert(){
            target.emit('closeAlert');
        }
        function share(){
            // if (!navigator.clipboard) {
            //     return
            // }
            // navigator.clipboard.writeText().then(() => {
            //     alert('');
            // });
            gtag('event', '「分享護照」', {
                '事件描述': '「分享護照」點擊次數',
            });
            if(accountType.value == 'guest')
                window.location.href = `https://www.facebook.com/dialog/share?app_id=553026519744426&display=popup&href=https://digitalworld-iwin.tw/&redirect_uri=https://digitalworld-iwin.tw/`
            else
                window.location.href =  `https://www.facebook.com/dialog/share?app_id=553026519744426&display=popup&href=${shareUrl.value}&redirect_uri=${shareUrl.value}`;
        }
        return { cover, grade, goPassport, closeAlert, share }
    }
}
</script>
<style lang="scss" scoped>
.passportAlert{
    width:100%;
    height:100%;
    background-color:rgba($color: #000000, $alpha: 0.65);
    top:0;
    left:0;
    position:absolute;
    .wrap{
        position:absolute;
        top: 50%;
        position: relative;
        transform: translateY(-50%);
    }
    .cross{
        width:24px;
            margin: 0 8% 20px auto;
    }
    .passport{
        width: 65%;
        padding-bottom: 76%;
        background-size: 100%;
        background-repeat: no-repeat;
        margin-bottom: 10%;
        border: 7px solid #fff;
        border-radius: 25px;
        box-shadow: 0px 4px 4px 0 rgba($color: #000000, $alpha: 0.25);
        position: relative;
        left: 50%;
        transform: translate(-50%);
        img{
            width: 12%;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 7%;
        }
    }
    button{
        &:nth-of-type(1){
            margin-right: 5px;
        }
        &:nth-of-type(2){
            margin-left: 5px;
        }
    }
}
</style>