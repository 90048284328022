<template>
  <router-view/>
  <img  v-show="logoToggle" id="logo_s" src="@/assets/img/logo_l.png" alt="">
  <Menu @menuClick="menuClick" ref="menu" v-if="logoToggle"/>
  <div class="background" v-if="messageToggle">
    <Message :title="about.title" :btn_txt="about.btn" @menuClick="menuClick" @clickTrig="clickTrig">
      <template v-slot:txt>
          <p>歡迎來到數位公民群島！<br>數位公民代表我們在網路世界承擔權利與義務的身份，這裡將幫助大家提升數位公民素養，學習更正確與安全使用網路的方法。<br><br><br>初訪的朋友請先至護照製作所申辦護照，創辦角色通過面試關卡後，即可進入數位世界探索議題島嶼。<br><br><br>請盡情享受這趟旅程吧！</p>
      </template>
    </Message>
  </div>
  <audio
    hidden="true"
    ref="bgmA"
    >
    <source  src="@/assets/music/painting/bgm_a.mp3" type="audio/mpeg">
  </audio>
</template>
<script>
import axios from 'axios'
import { useRoute, useRouter } from 'vue-router'
import { computed, ref, onMounted, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import Menu from '@/components/Menu.vue'
import Message from '@/components/Message.vue'
import { getProfileApi } from '@/api'
export default {
  components: {
    Menu, Message
  },
  setup () {
    const store = useStore()
    const messageToggle = ref(false)
    const route = useRoute()
    const router = useRouter()
    const path = computed(() => route.name)
    const menu = ref(null)
    const bgmA = ref(null)
    const accountType = computed(() => {
      return store.state.accountType
    })
    const logoToggle = computed(() => {
      if (accountType.value != null) {
        return path.value === 'home' || path.value === 'world' || path.value === 'passport'
      } else {
        return false
      }
    })
    const about = ref({
      title: '- 關於數位公民群島 -',
      btn: '繼續旅程'
    })
    onBeforeMount(() => {
      loginCheck()
      // if(localStorage.getItem('cover')){
      //   store.dispatch("SetCover", localStorage.getItem('cover'));
      //   store.dispatch("SetGrade", localStorage.getItem('grade'));
      //   store.dispatch("SetOath", localStorage.getItem('oath'));
      //   store.dispatch("SaveInfoPage", localStorage.getItem('infoPage'));
      // }
      // if(localStorage.getItem('record')){
      //   const list = localStorage.getItem('record').split(',');
      //   let newArr = [];
      //   list.forEach((item)=>{
      //     newArr.push(item);
      //   })
      //   store.dispatch("SetRecordList", newArr);
      // }
    })
    onMounted(() => {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    })
    function loginCheck () {
      const url = window.location.href
      const Url = new URL(url)
      if (Url.search) {
        const params = Url.searchParams
        if (params.has('token')) {
          localStorage.setItem('token', params.get('token'))
          const token = 'Bearer ' + window.localStorage.getItem('token')
          axios.defaults.headers.common['Authorization'] = token
          const valiable = url.split("?")[0]
          router.push({ name: 'home' })
          //window.history.pushState({},0,valiable);
          getProfile()
        }
      } else {
        if (localStorage.getItem('token')) {
          getProfile()
        }
      }
    }
    function getProfile () {
      getProfileApi().then((res) => {
        if (res.data.passport) {
          store.dispatch('SetPassport', res.data)
        }
        store.dispatch('SetSerial', res.data.serial)
        store.dispatch('SetAccountType', 'member')
      })
    }
    function menuClick (to) {
      switch (to) {
        case 'aboutIsland':
          messageToggle.value = true
          menu.value.menuToggle = false
          break
        case 'share':
          gtag('event', '「分享本站」', {
            '事件描述': '「分享本站」點擊次數',
          });
          window.location.href = 'https://www.facebook.com/dialog/share?app_id=553026519744426&display=popup&href=https://digitalworld-iwin.tw/&redirect_uri=https://digitalworld-iwin.tw/'
          break;
        case 'contact':
          gtag('event', '「聯絡我們」', {
            '事件描述': '「聯絡我們」點擊次數',
          })
          window.location.href = 'https://www.surveycake.com/s/v1Bgy'
          break;
        default:
          break;
      }
    }
    function clickTrig () {
      messageToggle.value = false
    }
    // function playPainting () {
    //   bgmA.value.play()
    // }
    return { logoToggle, menuClick, about, messageToggle, menu, clickTrig, bgmA }
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/_global.scss";
@import "@/assets/scss/_globalTransition.scss";
* {
  margin: 0;
  padding: 0;
}
html,body{
  overflow: hidden;
  -webkit-user-select:none;
  -moz-user-select:none;
  -o-user-select:none;
  user-select:none;
  height:100%;
  width: 100%;
}
body { -webkit-touch-callout: none !important; }
a { -webkit-user-select: none !important; }
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  // width:100%;
  // height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  // max-height: 667px;
  // max-width:375px;
  // width:375px;
  // height:667px;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
  @media (min-width: 576px) {
    max-width:375px;
    height:667px;
    border-radius: 15px;
  }
}
#logo_s{
  width: 72px;
  position: absolute;
  left: 10px;
  top: 15px;

}
.background{
  position: absolute;
  width:100%;
  height:100%;
  left:0;
  top:0;
  background-color: rgba($color: #000000, $alpha: 0.65);
  z-index: 9;
  .message{
    p{
      text-align: center;
    }
  }
}
</style>