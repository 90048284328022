<template>
    <div class="home container" ref="world" v-on:pointerdown="pointerdown" v-on:pointermove="pointermove"
                    v-on:pointerup="pointerup"
                    v-on:pointerout="pointerup">
        <div class="map_container" :style="[mapInfo , imgTransform]">
            <img class="map" ref="map"  @load="onImgLoad"  src="@/assets/img/home/home_back.png" alt="">
            <div class="house_l house" :class="{hover:houseL}" @click="openWorld" @mouseenter="houseL = true" @mouseleave="houseL = false">
                <img src="@/assets/img/home/house_l.png" alt="">
                <img src="@/assets/img/home/house_l_light.png" alt="">
                <div class="btn">
                    <img src="@/assets/img/home/island_entrance.png" alt="">
                    <img src="@/assets/img/home/island_entrance_hover.png" alt="">
                </div>
            </div>
            <div class="house_s house" :class="{hover:houseS}" @click="openMaker" @mouseenter="houseS = true" @mouseleave="houseS = false">
                <img src="@/assets/img/home/house_s.png" alt="">
                <img src="@/assets/img/home/house_s_light.png" alt="">
                <div class="btn">
                    <img src="@/assets/img/home/passport_maker.png" alt="">
                    <img src="@/assets/img/home/passport_maker_hover.png" alt="">
                </div>
            </div>
            <div class="walk"></div>
            <div class="seat"></div>
            <div class="bubble"></div>
            <img style="position:absolute; left:54.5%; top:48%; width:5%;" src="@/assets/img/home/entranc_npc.png" alt="">
        </div>
        <Passport @openPassport="openPassport" />
        <div class="background" v-if="congrats">
            <Message :title="'🎊🎊🎊<br>恭喜您已經申請通過數位公民群島護照！'" :btn_txt="'查看護照'"  @clickTrig="clickTrig">
            </Message>
        </div>
        <PassportAlert v-if="passportAlert" @closeAlert="closeAlert"/>
         <div class="background" v-if="noMaked">
            <Message :title="'您尚未申請數位公民護照，請到「護照製作所」免費申請！'" :btn_txt="'知道了'"  @clickTrig="closeNoMaked">
            </Message>
        </div>
        <audio
        hidden="true"
        ref="audio"
        loop
        >
            <source  src="@/assets/music/home.mp3" type="audio/mpeg">
        </audio>
        <audio
        hidden="true"
        ref="passport"
        >
            <source  src="@/assets/music/passport.mp3" type="audio/mpeg">
        </audio>
        <audio
        hidden="true"
        ref="check"
        >
            <source  src="@/assets/music/check.mp3" type="audio/mpeg">
        </audio>
    </div>
    <SocailLogin @closeLogin="closeLogin" v-if="accountType == null"/>
    <div id="firstUse" v-if="firstUse" style="width:100%;height:100%;background-color:rgba(0,0,0,0.6);z-index:9999;position:absolute;top:0;left:0;">
        <div class="tips" style="width:145px;position:absolute;left:50%;top:50%;transform:translate(-50%, -50%);">
            <div class="hand" style="display:flex;justify-content: space-between;">
                <img style="width:42px;align-self: center;" src="@/assets/img/arrow.png" alt="">
                <img class="palm" style="width:43px;align-self: center;" src="@/assets/img/hand.png" alt="">
                <img style="width:42px;align-self: center;transform:rotate(180deg);" src="@/assets/img/arrow.png" alt="">
            </div>
            <h3 style="color: white;font-size: 16px;letter-spacing: 0.1em;">拖動地圖開始探索</h3>
        </div>
    </div>
</template>
<script>

import { ref, reactive, onMounted, computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import SocailLogin from '@/components/login/SocialLogin.vue'
import Passport from '@/components/home/Passport.vue'
import PassportAlert from '@/components/home/PassportAlert.vue'
import axios from "axios";
import qs from "qs";
import Message from "@/components/Message.vue";

export default {
    components: {
        SocailLogin:SocailLogin,
        Passport:Passport,
        Message:Message,
        PassportAlert:PassportAlert
    },
    setup(){
        const route = useRoute();
        const router = useRouter();
        const store = useStore();
        // const currentInstance = getCurrentInstance();
        // const { $http } = currentInstance.appContext.config.globalProperties;
        let houseL = ref(false);
        let houseS = ref(false);
        let passportAlert = ref(false);
        let congrats = ref(false);
        let noMaked = ref(false);
        let socialToggle = ref(true);
        let audio = ref(null);
        const passport = ref(null);
        const check = ref(null);
        let firstUse = ref(false);
        let clickStatus = false;
        let zoomControl = reactive({
                imgInfo:{
                    width:0,
                    height:0,
                    left:0,
                    top:0
                },
                zoom:0,
                rotate:0,
                offset:{
                    x: 0,
                    y: 0,
                },
                imgContainer:{
                    width:0,
                    height:0,
                },
                dragging: false,
                point:{
                    x: 0,
                    y: 0,
                },
                minL:0,
                maxL:0,
                minT:0,
                maxT:0,
        })
        const world = ref(null);
        const map = ref(null);
        const passPortCheck = computed(()=>{
            return store.getters.passportCheck
        })
        const accountType = computed(()=>{
            return store.state.accountType;
        })
        let zoomset = 1;
        const mapInfo = computed(()=>{
            return {
                width: `${zoomControl.imgInfo.width}px`,
                height: `${zoomControl.imgInfo.height}px`,
                left: `${zoomControl.imgInfo.left}px`,
                top: `${zoomControl.imgInfo.top}px`
            }
        })
        const imgTransform = computed(()=>{
                //return {transform: `translate( ${this.zoomControl.offset.x}px, ${this.zoomControl.offset.y}px ) rotate( ${this.zoomControl.rotate}deg)`}
                return {transform: `rotate( ${zoomControl.rotate}deg)`, marginLeft: `${zoomControl.offset.x}px`, marginTop: `${zoomControl.offset.y}px`}
        })
        watch(()=> store.state.accountType, function() {
            if(store.state.accountType != null)
                closeLogin();
            let timer = null;
            if(localStorage.getItem('firstUse')){
                firstUse.value = false;
            }else{
                localStorage.setItem('firstUse', true);
                firstUse.value = true;
                timer = setTimeout(()=>{
                    firstUse.value = false;
                },2000);
            }
        })
        onMounted(() => {    

            if(route.query.maker){
                if(accountType.value == null)
                    store.dispatch('SetAccountType','guest')
                passportAlert.value = true;
                passport.value.play();
                closeLogin();
            }

        })
        function initImgContainer(){
            zoomControl.imgContainer.width = world.value.clientWidth;
            zoomControl.imgContainer.height = world.value.clientHeight;
            zoomControl.imgInfo.width = Math.floor( ( zoomControl.imgContainer.height / map.value.naturalHeight ) *  map.value.naturalWidth ) ;
            zoomControl.imgInfo.height = zoomControl.imgContainer.height ;
            zoomControl.imgInfo.left = Math.floor((zoomControl.imgContainer.width - ( zoomControl.imgContainer.height / map.value.naturalHeight ) *  map.value.naturalWidth) / 2 ) ;
            zoomControl.imgInfo.top = 0;
            for(let i = 0; i < zoomset; i++ ){
                imgControl('zoomIn');
            }
        }
        function imgControl(status){
            let width = zoomControl.imgInfo.width;
            let height = zoomControl.imgInfo.height;
            let top = zoomControl.imgInfo.top;
            let left = zoomControl.imgInfo.left;
            let offset = 1;
            switch (status) {
                case 'zoomIn':
                    zoomControl.imgInfo.width = width * offset;
                    zoomControl.imgInfo.height = height * offset;
                    zoomControl.imgInfo.top = (zoomControl.imgContainer.height - height * offset) / 2 
                    zoomControl.imgInfo.left = (zoomControl.imgContainer.width - width * offset) / 2  
                    zoomControl.zoom += 1;
                    zoomControl.minL = (zoomControl.imgInfo.width - zoomControl.imgContainer.width) / -2;
                    zoomControl.maxL = zoomControl.minL * -1;
                    zoomControl.minT = (zoomControl.imgInfo.height - zoomControl.imgContainer.height) / -2;
                    zoomControl.maxT = zoomControl.minT * -1;
                    break;
                case 'zoomOut':
                    if(width - offset >= zoomControl.imgContainer.width && height - offset >= zoomControl.imgContainer.height){
                        zoomControl.imgInfo.width = width / offset ;
                        zoomControl.imgInfo.height = height / offset;
                        zoomControl.imgInfo.top = (zoomControl.imgContainer.height - height / offset) / 2;
                        zoomControl.imgInfo.left = (zoomControl.imgContainer.width - width / offset) / 2 ;
                        zoomControl.zoom -= 1;
                        if(zoomControl.zoom == 0){
                            zoomControl.offset.x = 0 ;
                            zoomControl.offset.y = 0;
                        }
                    }else{
                        zoomControl.zoom = 0;
                        zoomControl.offset.x = 0 ;
                        zoomControl.offset.y = 0;
                        initImgContainer();
                    }
                    break;
                case 'reset':
                    zoomControl.zoom = 0;
                    zoomControl.offset.x = 0 ;
                    zoomControl.offset.y = 0;
                    initImgContainer();
                    break;
                case 'onetoone':
                    zoomControl.imgInfo.width = map.value.naturalWidth;
                    zoomControl.imgInfo.height = map.value.naturalHeight;
                    zoomControl.imgInfo.left = Math.floor( ( world.value.clientWidth - map.value.naturalWidth ) / 2 );
                    zoomControl.imgInfo.top = Math.floor( ( world.value.clientHeight - map.value.naturalHeight ) / 2 );
                    break;
                default:
                    break;
            }
        }
        function onImgLoad(){
            initImgContainer();
            if(audio.value.paused)
                audio.value.play();
        }
        function pointerdown(event){
            if(audio.value.paused)
                audio.value.play();
            event.preventDefault();
            event.stopPropagation();
            zoomControl.dragging = true;
            clickStatus = true;
            const {x, y} = getXY(event);
            zoomControl.point.x = x - zoomControl.offset.x;
            zoomControl.point.y = y - zoomControl.offset.y;
        }
        function getXY({clientX, clientY}){
            const {left, top} = world.value.getBoundingClientRect();
            return {x: clientX - left, y: clientY - top}
        }
        function pointermove(event){
            clickStatus = false;
            if (!zoomControl.dragging) return;
            const {x, y} = getXY(event);
            if( x - zoomControl.point.x <= zoomControl.minL)
                zoomControl.offset.x = zoomControl.minL;
            else if(x - zoomControl.point.x >= zoomControl.maxL)
                zoomControl.offset.x = zoomControl.maxL;
            else 
                zoomControl.offset.x = x - zoomControl.point.x;
            
            if( y - zoomControl.point.y <= zoomControl.minT)
                zoomControl.offset.y = zoomControl.minT;
            else if(y - zoomControl.point.y >= zoomControl.maxT)
                zoomControl.offset.y = zoomControl.maxT;
            else 
                zoomControl.offset.y = y - zoomControl.point.y;
        }
        function pointerup(){
            clickStatus = true;
            zoomControl.dragging = false;
        }
        function openMaker(){
            if(!clickStatus)return;
            check.value.play();
            if(passPortCheck.value)
                congrats.value = true;
            else
                router.push({name:'passportmaker'});
        }
        function closeAlert(){
            passportAlert.value = false;
        }
        function openPassport(){
            check.value.play();
            if(passPortCheck.value)
                passportAlert.value = true;
            else
                noMaked.value = true;
        }

        function closeNoMaked(){
            noMaked.value = false;
        }
        function clickTrig(){
            congrats.value = false;
            passportAlert.value = true;
        }
        function openWorld(){
            if(!clickStatus)return;
            check.value.play();
            if(passPortCheck.value)
                router.push({name:'world'});
            else
                noMaked.value = true;
        }
        function closeLogin(){
            socialToggle.value = false;
        }
        return { firstUse, check, passport, pointerdown, pointermove, pointerup, onImgLoad, mapInfo, imgTransform, world, map, openMaker, congrats, closeAlert, passportAlert, openPassport, closeNoMaked, noMaked, clickTrig, openWorld, houseL, houseS, closeLogin, socialToggle, audio, accountType }
    }
}
</script>
<style>
body{
    background-color:#06CFE6;
}
</style>
<style lang="scss" scoped>
.home{
    // height:100%;
    position:relative;
    overflow: hidden;
    cursor: grab;
    touch-action:none;
    .map_container{
        transition-property: transform, left, top, margin;
        transition-duration: 0.3s, 0.3s, 0.3s, 0s;
        transition-timing-function: ease, ease, ease, ease, linear;
        transition-delay: 0s;
        position:relative;
        .avatar{
            position: absolute;
        }
        .map{
            position: absolute;
            pointer-events: none;
            width:100%;
            height:100%;
        }
        .house{
            position:absolute;
            .btn{
                box-shadow: 0 4px 4px 0 rgba($color: #000000, $alpha: 0.25);
                border-radius: 8px;
            }
            &.house_l{
                position: absolute;
                width: 52%;
                left: 11.9%;
                top: 19.3%;
                .btn{
                    position: absolute;
                    left: 26%;
                    width: 39%;
                    bottom: -1%;
                }
            }
            &.house_s{
                position: absolute;
                width: 22%;
                left: 69.9%;
                top: 38.3%;
                .btn{
                    width: 87%;
                    left: 6%;
                    bottom: -35%;
                    position: absolute;
                }
            }
            &.hover{
                img{
                    &:nth-of-type(1){
                        display: none;
                    }
                    &:nth-of-type(2){
                        display: block;
                    }
                }
            }
            img{
                &:nth-of-type(2){
                    display: none;
                }
            }
        }
        .walk{
            position: absolute;
            width:14%;
            padding-bottom: 14%;
            background-image: url(~@/assets/img/home/walkAni.png);
            background-repeat: no-repeat;
            background-size: 1200%;
            animation: walk 6s steps(11) infinite;
            left: 58%;
            top: 62%;
        }
        .seat{
            position: absolute;
            width:7%;
            padding-bottom: 7%;
            background-image: url(~@/assets/img/home/seatAni.png);
            background-repeat: no-repeat;
            background-size: 900%;
            animation: walk 6s steps(9) infinite;
            left: 52%;
            top: 75.8%;
        }
        .bubble{
            position: absolute;
            width: 11%;
            padding-bottom: 18%;
            background-image: url(~@/assets/img/home/bubble.png);
            background-repeat: no-repeat;
            background-size: 900%;
            animation: walk 4s steps(7) infinite;
            left: 27.4%;
            top: 12.3%;
        }
    }
    .zIndex99{
        z-index: 99;
    }
    #noteat{
        position: absolute;
        width: 15%;
        bottom: 27%;
        right: 42%;
    }
    #back_to_lobby{
        position: absolute;
        width: 41%;
        bottom: 1%;
        right: 4%;
    }
    @keyframes walk {
        from{
            background-position: 0%;
        }
        to{
            background-position: 100%;
        }
    }
    
    .background{
        position: absolute;
        width:100%;
        height:100%;
        left:0;
        top:0;
        background-color: rgba($color: #000000, $alpha: 0.65);
        z-index: 9;
        .message{
            p{
            text-align: center;
            }
        }
    }
}
.palm{
    animation: swing 2s infinite;
    transition: ease-in;
}
@keyframes swing {
    0%{
        transform:translateX(10px);
    }
    50%{
        transform:translateX(-10px)
    }
    100%{
        transform:translateX(10px)
    }
}
</style>