import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/world',
    name: 'world',
    component: () => import(/* webpackChunkName: "about" */ '../views/World.vue')
  },
  {
    path: '/passportmaker',
    name: 'passportmaker',
    component: () => import(/* webpackChunkName: "about" */ '../views/PassportMaker.vue')
  },
  {
    path: '/passport',
    name: 'passport',
    component: () => import(/* webpackChunkName: "about" */ '../views/BookFlip.vue')
  },
  {
    path: '/noteat',
    name: 'noteat',
    component: () => import('../views/NotEat.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('../views/Privacy.vue')
  },
  {
    path: '/share',
    name: 'share',
    component: () => import('../views/Share.vue')
  },
  {
    path: '/painting',
    name: 'painting',
    component: () => import('../views/Painting.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
