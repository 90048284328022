import { createStore } from 'vuex'
import questions from '@/json/question.json'
import { calc } from '@/scorecalc/painting.js'
export default createStore({
  state: {
    avatar: null,
    guest: true,
    serial: 0,
    infoData: null,
    interviewer: 0,
    nowDate: null,
    passportInfo: {
      name: '',
      gender: '',
      birth_date: '',
      birth_place: '',
      emoji: '',
      image_1: '',
      identity: '',
      image_2: '',
      completedAt: 0,
      share_url: ''
    },
    grade: null,
    accountType: null,
    game: [],
    // infoData:'line',
    paintingQList: [],
    coinResult: {
      coin: 1,
      copywriting: 1,
      adam: true,
      girl: true,
      monalisa: true
    }
  },
  getters: {
    avatar (state) {
      return state.avatar
    },
    passportCheck (state) {
      return state.passportInfo.completedAt ? true : false
    },
    passportData (state) {
      return {
        cover: state.passportInfo.identity,
        grade: state.grade,
        infoPage: state.passportInfo.image_1,
        oath: state.passportInfo.image_2,
        record: state.game
      }
    }
  },
  mutations: {
    SAVEAVATAR (state, img) {
      state.avatar = img
    },
    SETINFODATA (state, data) {
      state.infoData = data
    },
    SAVESIGN (state, img) {
      state.passportInfo.image_2 = img
    },
    SETINTERVIEWER (state, num) {
      state.interviewer = num
    },
    SETDATE (state, data) {
      state.nowDate = data
    },
    SETGRADE (state, data) {
      state.grade = data
    },
    SETCOVER (state, data) {
      state.passportInfo.identity = data
    },
    SETOATH (state, img) {
      state.passportInfo.completedAt = 1
      state.passportInfo.image_2 = img
    },
    SETACCOUNTTYPE (state, type) {
      state.accountType = type
    },
    SETRECORD (state, data) {
      state.game.push(data)
    },
    SAVEINFOPAGE (state, img) {
      state.passportInfo.image_1 = img
    },
    SETSERIAL (state, num) {
      state.serial = num
    },
    SETPASSPORT (state, data) {
      state.passportInfo = data.passport
      if (data.interview) {
        state.grade = data.interview.level
      }
      if (data.games) {
        state.game = data.games
      }
    },
    CLEARINFODATA (state) {
      state.infoData = null
    },
    SETQUESTIONLIST (state, list) {
      state.paintingQList = list
    },
    CALCANSWER (state, coinResult) {
      state.coinResult = coinResult
    }
  },
  actions: {
    SaveAvatar ({ commit }, img) {
      commit('SAVEAVATAR', img)
    },
    SetInfoData ({ commit }, data) {
      commit('SETINFODATA', data)
    },
    SaveSign ({ commit }, img) {
      commit('SAVESIGN', img)
    },
    SetInterview ({ commit }, num) {
      commit('SETINTERVIEWER', num)
    },
    SetDate ({ commit }) {
      let today = new Date()
      const dd = String(today.getDate()).padStart(2, '0')
      const mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
      const yyyy = today.getFullYear()
      today = yyyy + ' / ' + mm + ' / ' + dd
      commit('SETDATE', today)
    },
    SetGrade ({ commit }, grade) {
      commit('SETGRADE', grade)
    },
    SetCover ({ commit }, select) {
      commit('SETCOVER', select)
    },
    SetOath ({ commit }, img) {
      commit('SETOATH', img)
    },
    SetAccountType ({ commit }, type) {
      commit('SETACCOUNTTYPE', type)
    },
    SetRecord ({ commit }, gameData) {
      commit('SETRECORD', gameData)
    },
    SaveInfoPage ({ commit }, img) {
      commit('SAVEINFOPAGE', img)
    },
    SetSerial ({ commit }, num) {
      commit('SETSERIAL', num)
    },
    SetPassport ({ commit }, data) {
      commit('SETPASSPORT', data)
    },
    ClearInfoData ({ commit }) {
      commit('CLEARINFODATA')
    },
    ChooseQuestionList ({ commit }, order) {
      const qList = []
      order.forEach((key) => {
        const qType = [false, true, true]
        const badQ = JSON.parse(JSON.stringify(questions.questionList[key + 'Q'])).badQ
        const goodQ = JSON.parse(JSON.stringify(questions.questionList[key + 'Q'])).goodQ
        for (let index = 0; index < 3; index++) {
          const getType = qType.splice(getRandom(1, qType.length) - 1, 1)
          qList.push(questions.questionList[key][getType[0] ? goodQ.splice(getRandom(1, goodQ.length) - 1, 1)[0] : badQ.splice(getRandom(1, badQ.length) - 1, 1)[0]])
        }
      })
      commit('SETQUESTIONLIST', qList)
    },
    CalcAnswer ({ commit, state }, answerList) {
      commit('CALCANSWER', calc(state.paintingQList, answerList))
    }
  },
  modules: {
  }
})

function getRandom (min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}
