import axios from "axios";
const apiUrl = 'https://digitalworld-iwin.tw/api/'
const token = 'Bearer '+ window.localStorage.getItem("token");
axios.defaults.headers.common['Authorization'] = token;

const getProfileApi = param => axios.get( apiUrl + 'profile', param);
const setProfileApi = param => axios.post( apiUrl + 'profile', param );
const setInterviewApi = param => axios.post( apiUrl + 'interview', param);
const setIdentityApi = param => axios.post( apiUrl + 'identity', param);
const setOathApi = param => axios.post( apiUrl + 'complete_passport', param);
const setGameResultApi = param => axios.post( apiUrl + 'game', param);
const setSurveyApi = param => axios.post( apiUrl + 'survey', param);
const getPassportApi = id => axios.get( apiUrl + 'passport/' + id );
const setQuiz = (qNum, param) => axios.post(apiUrl + 'quiz/' + qNum, param);
export {
  getProfileApi,
  setProfileApi,
  setInterviewApi,
  setIdentityApi,
  setOathApi,
  setGameResultApi,
  setSurveyApi,
  getPassportApi,
  setQuiz
}