<template>
    <div class="message">
        <h3 v-show="title!=''" v-html="title"></h3>
        <p><slot name="txt"></slot></p>
        <slot name="img"></slot>
        <button class="pointer submit" @click="clickTrig">{{btn_txt}}</button>
    </div>
    <audio
        hidden="true"
        ref="check"
        preload="auto"
        @pause="pause"
        >
        <source  src="@/assets/music/check.mp3" type="audio/mpeg">
    </audio>
</template>
<script>
import { ref, onMounted } from 'vue'
export default {
    props:{
        title:{
            default:'',
            type:String
        },
        btn_txt:{
            default:'好',
            type:String
        }
    },
    emits:['clickTrig'],
    setup(props,{emit}){
        const check = ref(null);
        onMounted(()=>{

        })
        function clickTrig(){
            check.value.play();
        }
        function pause(){
            emit("clickTrig");
        }
        return { clickTrig, check, pause }
    }
}
</script>
<style lang="scss" scoped>
.message{
    background-color: white;
    width: 282px;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50%);
    position: relative;
    padding: 25px 44px;
    border-radius: 8px;
    h3{
        font-size: 16px;
        color:#055078;
        margin-bottom: 20px;
        font-weight: bold;
        letter-spacing: 0.1em;
    }
    p{
        color:black;
        font-size: 12px;
        margin-bottom: 25px;
        text-align: left;
        letter-spacing: 0.05em;
        line-height: 1.5;
    }
    button{
        background: linear-gradient(0.25turn, #EE837B, #EB6E76);
        color:white;
    }
}
</style>