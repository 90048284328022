<template>
    <div class="passport">
        <img src="@/assets/img/home/subtract.png" alt="" style="pointer-events: none;">
        <div class="content" @click="openPassport">
            <div class="passportCover" :style="{backgroundImage:`url(${cover})`}">
                <img v-if="grade!='empty'" :src="grade" alt="">
            </div>
            <h2>我的護照</h2>
            <p>PASSPORT</p>
        </div>
    </div>
</template>
<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import defaultCove from '@/assets/img/home/default_cover.png'
export default {
    setup(props, target){
        const store = useStore();
        const passport = computed(()=>{
            return store.getters.passportData;
        })
        const passportCheck = computed(()=>{
            return store.getters.passportCheck;
        })
        const cover = computed(()=>{
            if(passportCheck.value){
                return `/img/cover/cover${passport.value.cover - 1}.png`
            }
            else {
                return defaultCove;
            }
        })
        const grade = computed(()=>{
            if(store.state.grade){
                return `/img/grade/grade${store.state.grade}.png`
            }else{
                return 'empty'
            }
             
        })
        function openPassport(){
            target.emit('openPassport');
        }
        return { cover, grade, openPassport }
    }
}
</script>
<style lang="scss" scoped>
.passport{
    position:absolute;
    right:0;
    bottom:0;
    width:45%;
    cursor:pointer;
    .content{
        position: absolute;
        bottom:6%;
        left:55%;
        transform: translateX(-50%);
        text-align: center;
        width: 40%;
        .passportCover{
            width: 100%;
            padding-bottom: 118%;
            background-size: 100%;
            background-repeat: no-repeat;
            margin-bottom: 10%;
            border: 2px solid #fff;
            border-radius: 8px;
            box-shadow: 0px 4px 4px 0 rgba($color: #000000, $alpha: 0.25);
            position: relative;
            img{
                width: 17%;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: 4%;
            }
        }
        h2{
            font-size: 14px;
            color:#055078;
            font-weight: bold;
        }
        p{
            font-size: 12px;
            color: #A8A8A8;
        }
    }
}
</style>